import { ServiceStationDto } from '../api/service-stations/service-stations.dto';

const Weekday: { [key in number]: string } = {
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
  7: 'Вс',
};

export const getSchedule = (serviceStation: ServiceStationDto): string[] => {
  const { schedule } = serviceStation;

  if (schedule) {
    if (schedule.length === 1) {
      return [`Ежедневно: ${schedule[0].openHours[0]} - ${schedule[0].openHours[1]}`];
    } else {
      return schedule.map(
        (item) =>
          `${Weekday[item.weekdays[0]]}${
            item.weekdays[1] ? `-${Weekday[item.weekdays[1]]}` : ''
          }: ${
            item.openHours.length === 2
              ? `${item.openHours[0]} - ${item.openHours[1]}`
              : item.openHours[0]
          }`,
      );
    }
  }

  return [];
};
