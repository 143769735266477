export const getNoun = (count: number, one: string, two: string, five: string) => {
  if ((count %= 100) >= 5 && (count %= 100) <= 20) {
    return five;
  } else if ((count %= 10) === 1) {
    return one;
  } else if ((count %= 10) >= 2 && (count %= 10) <= 4) {
    return two;
  } else {
    return five;
  }
};
