import { RatingDto, ServiceStationDto } from '../api/service-stations/service-stations.dto';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { useEffect, useState } from 'react';

export const useRatings = (
  ymaps: YMapsApi | undefined,
  serviceStations: ServiceStationDto[],
): RatingDto[] => {
  const [ratings, setRatings] = useState<RatingDto[]>([]);

  useEffect(() => {
    if (ymaps && serviceStations.length > 0) {
      const promises = Promise.all(
        serviceStations
          .filter((serviceStation) => serviceStation.organizationYandexId)
          .map(async (serviceStation) => {
            try {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const object = await ymaps.findOrganization(`${serviceStation.organizationYandexId}`);
              return { id: serviceStation.id, rating: object.properties.get('rating') };
            } catch (e) {
              return undefined;
            }
          }),
      );
      promises.then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setRatings(res.filter((item) => !!item));
      });
    }
  }, [ymaps, serviceStations]);

  return ratings;
};
