import { CityDto } from './cities.dto';
import { client } from '../client';

export const CitiesApi = {
  getAll: async (): Promise<CityDto[]> => {
    try {
      const { data } = await client.get('/cities');
      return data;
    } catch (e) {
      return [];
    }
  },
};
