import { BrandDto } from './brands.dto';
import { client } from '../client';

export const BrandsApi = {
  getAll: async (): Promise<BrandDto[]> => {
    try {
      const { data } = await client.get('/brands');
      return data;
    } catch (e) {
      return [];
    }
  },
};
