import { Filters } from './components/Filters';
import { HeroText } from './components/HeroText';
import { ServicesList } from './components/ServicesList';
import { ServicesMap } from './components/Map';
import { YMaps } from '@pbe/react-yandex-maps';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { getNoun } from './utils/getNoun';
import { useAllServiceStations, useServiceStations } from './hooks/useServiceStations';
import { useRatings } from './hooks/useRatings';
import React, { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import classes from './app.module.css';

function App() {
  const [ymaps, setYmaps] = useState<YMapsApi | undefined>();

  const [selectedId, setSelectedId] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [cityId, setCityId] = useState('');
  const [brandId, setBrandId] = useState('');

  const refList = useRef<HTMLUListElement>(null);
  const serviceStations = useServiceStations({ serviceId, cityId, brandId });
  const allServiceStations = useAllServiceStations();
  const ratings = useRatings(ymaps, allServiceStations);

  useLayoutEffect(() => {
    if (refList.current && !!selectedId?.length) {
      const listItem = document.querySelector(`[data-id="${selectedId}"]`) as HTMLElement;
      if (listItem) {
        refList.current.scrollTop = listItem.offsetTop - 420;
      }
    }
  }, [selectedId]);

  return (
    <YMaps
      query={{
        apikey: 'd8a1fbf3-e747-46c2-81ca-d65a5c98777c',
        load: 'package.full',
      }}
    >
      <div>
        <HeroText servicesValues={allServiceStations.length} />
        <div className="services-container">
          <Filters setBrandId={setBrandId} setCityId={setCityId} setServiceId={setServiceId} />
          <div>
            <p className={classNames(classes.sectionTitle, 'color-midle-gray')}>
              Найдено <span className="color-orange">{serviceStations.length}</span>{' '}
              {getNoun(serviceStations.length, 'сервис', 'сервиса', 'сервисов')}
            </p>
            <div className={classes.servicesBoxes}>
              <ServicesList
                refList={refList}
                selectedId={selectedId}
                ratings={ratings}
                serviceStations={serviceStations}
              />
              <ServicesMap
                setYmaps={setYmaps}
                serviceStations={serviceStations}
                setSelectedId={setSelectedId}
              />
            </div>
          </div>
        </div>
      </div>
    </YMaps>
  );
}

export default App;
