import { ServiceDto } from './services.dto';
import { client } from '../client';

export const ServicesApi = {
  getAll: async (): Promise<ServiceDto[]> => {
    try {
      const { data } = await client.get('/services');
      return data;
    } catch (e) {
      return [];
    }
  },
};
