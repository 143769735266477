export const Star = ({ active }: { active?: boolean }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={active ? '#ffcc00' : '#CCC'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.987 9.42l-3.26 1.991a.48.48 0 0 1-.715-.526l.945-3.764-2.74-2.133A.48.48 0 0 1 .47 4.13l3.586-.295L5.552.348a.48.48 0 0 1 .883.001l1.483 3.486 3.61.296a.48.48 0 0 1 .255.857L9.031 7.121l.943 3.766a.48.48 0 0 1-.715.527L5.987 9.419v.001z"
    ></path>
  </svg>
);
