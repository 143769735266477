import { Clock } from '../../assets/svg/Сlock';
import { Location } from '../../assets/svg/Location';
import { Phone } from '../../assets/svg/Phone';
import { RatingDto, ServiceStationDto } from '../../api/service-stations/service-stations.dto';
import { Star } from '../../assets/svg/Star';
import { Website } from '../../assets/svg/Website';
import { getSchedule } from '../../utils/getSchedule';
import React from 'react';
import classNames from 'classnames';
import classes from './service.module.css';

export const Service = ({
  serviceStation,
  ratings,
  selectedId,
}: {
  serviceStation: ServiceStationDto;
  ratings: RatingDto[];
  selectedId: string;
}) => {
  const rating = ratings.find((rating) => rating.id === serviceStation.id);

  return (
    <li
      className={classNames(
        classes.Service,
        'bg-gray',
        serviceStation.id === selectedId ? 'bg-active-gray' : '',
      )}
      data-id={serviceStation.id}
    >
      <div className={classes.Name}>
        <h4 className="color-orange">{serviceStation.name}</h4>
        {rating?.rating && (
          <a
            href={`https://yandex.ru/map-widget/v1/?poi%5Bpoint%5D=${serviceStation.fullAddress.coordinates.latitude}%2C${serviceStation.fullAddress.coordinates.longitude}&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D${serviceStation.organizationYandexId}&z=17`}
            target="_blank"
            className={classes.Stars}
          >
            {
              <>
                <Star active={rating.rating.score ? rating.rating.score > 1 : undefined} />
                <Star active={rating.rating.score ? rating.rating.score > 2 : undefined} />
                <Star active={rating.rating.score ? rating.rating.score > 3 : undefined} />
                <Star active={rating.rating.score ? rating.rating.score > 4 : undefined} />
                <Star active={rating.rating.score === 5} />
              </>
            }
            {rating.rating?.reviews && <p>({rating.rating?.reviews} отзывов)</p>}
          </a>
        )}
      </div>
      <div className={classes.Top}>
        <div className={classes.Info}>
          <p className={classes.Text}>
            <Location />
            <span>{serviceStation.fullAddress.address}</span>
          </p>
          <a className={classes.Text} href={`tel:${serviceStation.contacts.phone}`} target="_blank">
            <Phone />
            <span>{serviceStation.contacts.phone}</span>
          </a>
          <div className={classes.Text}>
            <Clock />
            <div>
              {getSchedule(serviceStation).map((item, idx) => (
                <p key={idx}>{item}</p>
              ))}
            </div>
          </div>
          {serviceStation.contacts.website && (
            <a
              className={classNames(classes.Website, classes.Text)}
              href={serviceStation.contacts.website.url}
              target="_blank"
            >
              <Website />
              <span>{serviceStation.contacts.website.title}</span>
            </a>
          )}
        </div>
        <div className={classes.Btns}>
          {serviceStation.formCompanyId && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <tr-form-button-i55Cfco5chlUNeJW
              form-id="sxc8ww0ac"
              company-set="deprler30"
              position="center"
              companies-ids={serviceStation.formCompanyId}
            >
              <button className="btn bg-orange color-white">Записаться</button>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
            </tr-form-button-i55Cfco5chlUNeJW>
          )}
          {serviceStation.contacts.url && (
            <a
              href={serviceStation.contacts.url}
              target="_parent"
              className={classNames(classes.ContactLink, 'btn bg-white color-orange')}
            >
              Контакты
            </a>
          )}
        </div>
      </div>
      <div className={classes.Bottom}>
        {!!serviceStation.services.length && (
          <div className={classes.BottomServices}>
            <h6 className="h6">Предоставляемые услуги:</h6>
            <p>{serviceStation.services.map((service) => service.name).join(', ')}</p>
          </div>
        )}
        {!!serviceStation.brands.length && (
          <div className={classes.BottomBrands}>
            <h6 className="h6">Бренды:</h6>
            <p>{serviceStation.brands.map((brand) => brand.name).join(', ')}</p>
          </div>
        )}
      </div>
    </li>
  );
};
