import { useBrands } from '../../hooks/useBrands';
import { useCities } from '../../hooks/useCities';
import { useServices } from '../../hooks/useServices';
import classNames from 'classnames';
import classes from './filters.module.css';

export const Filters = ({
  setBrandId,
  setCityId,
  setServiceId,
}: {
  setBrandId: (v: string) => void;
  setCityId: (v: string) => void;
  setServiceId: (v: string) => void;
}) => {
  const brands = useBrands();
  const cities = useCities();
  const services = useServices();

  return (
    <div className={classNames(classes.filters, 'bg-orange')}>
      <h3 className="color-white h3">Выбрать автосервиc Eurorepar</h3>
      <div className={classes.selects}>
        <select className={classes.selectOne} onChange={(e) => setCityId(e.target.value)}>
          {cities.map((city) => (
            <option key={city.id} value={city.id}>
              {city.city}
            </option>
          ))}
        </select>
        <select className={classes.selectTwo} onChange={(e) => setBrandId(e.target.value)}>
          {brands.map((brand) => (
            <option key={brand.id} value={brand.id}>
              {brand.name}
            </option>
          ))}
        </select>
        <select className={classes.selectThree} onChange={(e) => setServiceId(e.target.value)}>
          {services.map((service) => (
            <option key={service.id} value={service.id}>
              {service.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
