import { ServiceDto } from '../api/services/services.dto';
import { ServicesApi } from '../api/services/services.api';
import { useEffect, useState } from 'react';

export const useServices = (): ServiceDto[] => {
  const [services, setServices] = useState<ServiceDto[]>([]);

  useEffect(() => {
    ServicesApi.getAll().then((res) => {
      setServices([{ id: '', name: 'Все типы работ' }, ...res]);
    });
  }, []);

  return services;
};
