import { QueryParams, ServiceStationDto } from '../api/service-stations/service-stations.dto';
import { ServiceStationsApi } from '../api/service-stations/service-stations.api';
import { useEffect, useState } from 'react';

export const useServiceStations = ({
  cityId,
  serviceId,
  brandId,
}: QueryParams): ServiceStationDto[] => {
  const [serviceStations, setServiceStations] = useState<ServiceStationDto[]>([]);

  useEffect(() => {
    setServiceStations([]);
    ServiceStationsApi.getByFilters({
      cityId,
      brandId,
      serviceId,
    }).then((res) => {
      setServiceStations(res);
    });
  }, [cityId, brandId, serviceId]);

  return serviceStations;
};

export const useAllServiceStations = () => {
  const [serviceStations, setServiceStations] = useState<ServiceStationDto[]>([]);

  useEffect(() => {
    setServiceStations([]);
    ServiceStationsApi.getByFilters().then((res) => {
      setServiceStations(res);
    });
  }, []);

  return serviceStations;
};
