import { QueryParams, ServiceStationDto } from './service-stations.dto';
import { client } from '../client';

export const ServiceStationsApi = {
  getByFilters: async (queryParams?: QueryParams): Promise<ServiceStationDto[]> => {
    try {
      const { data } = await client.get(
        `/service-stations?${
          queryParams
            ? `cityId=${queryParams.cityId}&serviceId=${queryParams.serviceId}&brandId=${queryParams.brandId}`
            : ''
        }`,
      );
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};
