import { CitiesApi } from '../api/cities/cities.api';
import { CityDto } from '../api/cities/cities.dto';
import { useEffect, useState } from 'react';

export const useCities = (): CityDto[] => {
  const [cities, setCities] = useState<CityDto[]>([]);

  useEffect(() => {
    CitiesApi.getAll().then((res) => {
      setCities([{ id: '', city: 'Все города' }, ...res]);
    });
  }, []);

  return cities;
};
