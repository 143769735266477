import { BrandDto } from '../api/brands/brands.dto';
import { BrandsApi } from '../api/brands/brands.api';
import { useEffect, useState } from 'react';

export const useBrands = (): BrandDto[] => {
  const [brands, setBrands] = useState<BrandDto[]>([]);

  useEffect(() => {
    BrandsApi.getAll().then((res) => {
      setBrands([{ id: '', name: 'Все марки' }, ...res]);
    });
  }, []);

  return brands;
};
