import { Clusterer, Map, Placemark } from '@pbe/react-yandex-maps';
import { useEffect, useState } from 'react';

import { ServiceStationDto } from '../../api/service-stations/service-stations.dto';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import classes from './map.module.css';

const CustomPlacemark = ({
  serviceStation,
  setSelectedId,
}: {
  serviceStation: ServiceStationDto;
  setSelectedId: () => void;
}) => {
  const [onPin, setOnPin] = useState(false);
  return (
    <Placemark
      defaultGeometry={[
        serviceStation.fullAddress.coordinates.latitude,
        serviceStation.fullAddress.coordinates.longitude,
      ]}
      options={{ preset: onPin ? 'islands#yellowDotIcon' : 'islands#orangeDotIcon' }}
      onMouseEnter={() => setOnPin(true)}
      onMouseLeave={() => setOnPin(false)}
      onClick={setSelectedId}
    />
  );
};

export const ServicesMap = ({
  setYmaps,
  setSelectedId,
  serviceStations,
}: {
  setYmaps: (ymaps: YMapsApi) => void;
  setSelectedId: (v: string) => void;
  serviceStations: ServiceStationDto[];
}) => {
  const [ymaps, setApiYmaps] = useState<YMapsApi | undefined>();
  const [center, setCenter] = useState([55.751574, 37.573856]);
  const [onCluster] = useState(false);
  const [bounds, setBounds] = useState<number[][]>();

  useEffect(() => {
    if (serviceStations.length > 0 && ymaps) {
      if (serviceStations.length > 1) {
        setBounds(
          ymaps.util.bounds.fromPoints(
            serviceStations.map((serviceStation) => [
              parseFloat(serviceStation.fullAddress.coordinates.latitude),
              parseFloat(serviceStation.fullAddress.coordinates.longitude),
            ]),
          ),
        );
      } else {
        setCenter([
          parseFloat(serviceStations[0].fullAddress.coordinates.latitude),
          parseFloat(serviceStations[0].fullAddress.coordinates.longitude),
        ]);
      }
    }
  }, [serviceStations, ymaps]);

  return (
    <div className={classes.mapWrapper}>
      <Map
        id="map"
        width="100%"
        height="100%"
        state={{
          bounds,
          center,
          zoom: 7,
          margin: [20, 20, 20, 20],
          controls: undefined,
        }}
        options={{ suppressMapOpenBlock: true }}
        onLoad={(ymaps) => {
          setYmaps(ymaps);
          setApiYmaps(ymaps);
        }}
      >
        {ymaps && (
          <Clusterer
            options={{
              preset: onCluster ? 'islands#yellowClusterIcons' : 'islands#orangeClusterIcons',
            }}
            // onMouseEnter={() => setOnCluster(true)}
            // onMouseLeave={() => setOnCluster(false)}
          >
            {serviceStations.map((serviceStation, index) => (
              <CustomPlacemark
                serviceStation={serviceStation}
                setSelectedId={() => setSelectedId(serviceStation.id)}
                key={index}
              />
            ))}
          </Clusterer>
        )}
      </Map>
    </div>
  );
};
