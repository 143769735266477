import { MutableRefObject } from 'react';
import { RatingDto, ServiceStationDto } from '../../api/service-stations/service-stations.dto';
import { Service } from '../Service';
import classNames from 'classnames';
import classes from './services-list.module.css';

export const ServicesList = ({
  refList,
  ratings,
  serviceStations,
  selectedId,
}: {
  selectedId: string;
  ratings: RatingDto[];
  refList: MutableRefObject<HTMLUListElement | null>;
  serviceStations: ServiceStationDto[];
}) => {
  return (
    <ul className={classNames(classes.List, 'gap-15')} ref={refList}>
      {serviceStations.map((serviceStation) => (
        <Service
          ratings={ratings}
          serviceStation={serviceStation}
          selectedId={selectedId}
          key={serviceStation.id}
        />
      ))}
    </ul>
  );
};
