import { getNoun } from '../../utils/getNoun';
import classNames from 'classnames';
import classes from './hero-text.module.css';

export const HeroText = ({ servicesValues }: { servicesValues: number }) => {
  return (
    <div className={classNames(classes.heroSection, 'bg-light-gray')}>
      <div className={classNames(classes.heroContainer, 'bg-light-gray')}>
        <div className={classNames(classes.header, 'color-gray-dark')}>
          <h2 className="h2 upper-case">
            автосервисы <span className={classes.orange}>eurorepar car service™</span>
          </h2>
        </div>
        <div className={classNames(classes.main)}>
          <h5 className="color-orange h5">
            Сеть Eurorepar Car Service™ включает в себя больше {servicesValues}{' '}
            {getNoun(servicesValues, 'станции', 'станций', 'станций')} по всей России
          </h5>
        </div>
      </div>
    </div>
  );
};
