export const Phone = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.92849 3.29265C6.31896 2.90245 6.95175 2.90245 7.34222 3.29265L11.5798 7.52735C11.7675 7.71491 11.8729 7.96937 11.8729 8.2347C11.8729 8.50003 11.7675 8.75449 11.5798 8.94205L10.0059 10.5149L13.4807 13.9874L15.0556 12.4135C15.4461 12.0233 16.0789 12.0233 16.4693 12.4135L20.7069 16.6482C20.8946 16.8358 21.0001 17.0903 21.0001 17.3556C21.0001 17.6209 20.8946 17.8754 20.7069 18.0629L18.9655 19.8032C18.4837 20.2846 17.8801 20.6261 17.2193 20.7912C15.8241 21.1398 14.2567 21.0979 12.8867 20.3082C11.6316 19.5848 9.85904 18.3704 7.89107 16.4409C5.83431 14.4243 4.50405 12.4835 3.71696 11.1292C2.91744 9.75359 2.84991 8.17034 3.20562 6.74846C3.36601 6.10733 3.69774 5.52188 4.16524 5.0547L5.92849 3.29265ZM6.63536 5.41373L5.57897 6.4694C5.36788 6.68034 5.21819 6.94459 5.14583 7.23385C4.88451 8.27842 4.9679 9.30144 5.44612 10.1242C6.15948 11.3516 7.38408 13.1429 9.29126 15.0128C11.1291 16.8147 12.7642 17.9292 13.8855 18.5755C14.69 19.0391 15.6944 19.1107 16.7345 18.8509C17.0439 18.7736 17.3264 18.6137 17.5518 18.3885L18.5854 17.3556L15.7625 14.5346L14.1876 16.1085C13.7971 16.4987 13.1643 16.4987 12.7738 16.1085L7.8843 11.2223C7.69662 11.0347 7.59117 10.7803 7.59117 10.5149C7.59117 10.2496 7.69662 9.99513 7.8843 9.80758L9.45825 8.2347L6.63536 5.41373Z"
        fill="#606060"
      />
    </svg>
  );
};
